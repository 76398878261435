import React from "react";
import { Table, Typography } from "antd";
import "./ConsolidatedSummary.css";

const { Title } = Typography;
const ConsolidatedSummary = ({ summaryData, mediumData, title1, title2 }) => {
  // Columns for the first summary table
  console.log("summary data : " + JSON.stringify(summaryData));
  //   const formatNumber = (num) => {
  //     if (num === undefined || num === null) {
  //         return '-'; // Return a default value or placeholder if the number is missing
  //     }
  //     if (num >= 1e9) {
  //         return (num / 1e9).toFixed(1) + 'B'; // Billions
  //     } else if (num >= 1e6) {
  //         return (num / 1e6).toFixed(1) + 'M'; // Millions
  //     } else if (num >= 1e3) {
  //         return (num / 1e3).toFixed(1) + 'K'; // Thousands
  //     } else {
  //         return num.toString(); // Less than 1000
  //     }
  // };
  // const formatNumber = (num) => {
  //   if (num === undefined || num === null) {
  //     return "-"; // Return a placeholder for undefined or null values
  //   }

  //   // Convert to number in case it's passed as a string
  //   num = Number(num);

  //   if (num >= 1e12) {
  //     return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
  //   } else if (num >= 1e9) {
  //     return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
  //   } else if (num >= 1e6) {
  //     return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
  //   } else if (num >= 1e3) {
  //     return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
  //   } else {
  //     return num.toString(); // Less than 1000
  //   }
  //   // return num.toString();
  // };
  const formatNumber = (num) => {
    if (num === undefined || num === null) {
      return "-"; // Return a placeholder for undefined or null values
    }

    // Convert to number in case it's passed as a string
    num = Number(num);

    // Round the number to avoid floating-point precision issues
    const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places

    if (roundedNum >= 1e12) {
      return (roundedNum / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (roundedNum >= 1e9) {
      return (roundedNum / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (roundedNum >= 1e6) {
      return (roundedNum / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (roundedNum >= 1e3) {
      return (roundedNum / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return roundedNum.toString(); // Less than 1000
    }
  };

  // Columns for the medium summary table
  const summaryColumns = [
    {
      title: "City",
      dataIndex: "city_name",
      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            children: <div>{text}</div>,
            props: {
              colSpan: 5, // Merge with the next 4 cells
              style: { textAlign: "center" }, // Center-align the text
            },
          };
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "SOV",
      dataIndex: "sov",
      key: "sov",

      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            children: <div>{formatNumber(text)}</div>,
            props: {
              colSpan: 0,
            },
          };
        }

        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "SOV%",
      dataIndex: "sov_percentage",
      key: "sov_percentage",

      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "SOS",
      dataIndex: "sos",
      key: "sos",
      // width: '158px',
      render: (text, record) => {
        if (record.key === "oma" || record.key === "total_plan") {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "SOS%",
      dataIndex: "sos_percentage",
      key: "sos_percentage",

      render: (text, record) => {
        console.log("text: " + text + " record : " + JSON.stringify(record));

        const numericValue =
          typeof text === "string"
            ? parseFloat(text.replace("%", "").trim())
            : typeof text === "number"
            ? text
            : 0; // Default to 0 if text is neither a string nor a number

        console.log("text: " + text + "record : " + JSON.stringify(record));
        if (record.key == "oma" || record.key === "total_plan") {
          return {
            children: <div>{formatNumber(numericValue)}</div>, // Correctly wrap in 'children'
          };
        }
        return <div>{formatNumber(numericValue)}%</div>;
      },
    },
  ];

  const mediumColumns = [
    { title: "City", dataIndex: "city_name", key: "city_name" },
    { title: "Hoardings", dataIndex: "hoarding", key: "hoarding" },
    { title: "Wall Panels", dataIndex: "wall_panel", key: "wall_panel" },
    {
      title: "Digital Poles",
      dataIndex: "digital_pole_signs",
      key: "digital_pole_signs",
    },
    { title: "SMD Sign", dataIndex: "digital_smd", key: "digital_smd" },
  ];
  // Data source for the summary table with totals row
  const summaryDataSource = [
    ...summaryData.sites,
    {
      city_name: "Total",
      quantity: summaryData.total_quantity,
      sov: summaryData.total_sov,
      sov_percentage: summaryData.total_sov_percentage,
      sos: summaryData.total_sos,
      sos_percentage: summaryData.total_sos_percentage,
      key: "total",
    },
    {
      city_name: `OMA ${summaryData.oma_percentage * 100}%`,
      //   quantity: "",
      //   sov: "",
      //   sos: "",
      sos_percentage: summaryData.oma,
      key: "oma",
    },
    {
      city_name: "Total Plan",
      //   quantity: "",
      //   sov: "",
      //   sos: "",
      sos_percentage: summaryData.total_plan,
      key: "total_plan",
    },
  ];
  // Data source for the medium summary table with totals row
  const mediumDataSource = [
    ...mediumData.sites,
    {
      city_name: "Total",
      hoarding: mediumData.total_hoarding,
      wall_panel: mediumData.total_wall_panel,
      digital_pole_signs: mediumData.total_digital_pole_signs,
      digital_smd: mediumData.total_digital_smd,
      key: "total_medium",
    },
  ];
  return (
    <div className="consolidated-summary">
      <div className="summary-container">
        <div className="table-wrapper">
          <Title level={4}>{title1}</Title>

          <Table
            className="summary-table"
            columns={summaryColumns}
            dataSource={summaryDataSource}
            pagination={false}
            rowClassName={(record) =>
              record.key === "total" ||
              record.key === "oma" ||
              record.key === "total_plan"
                ? "total-row"
                : ""
            }
            bordered
          />
        </div>
        <div className="table-wrapper">
          <Title level={4}>{title2}</Title>

          <Table
            className="summary-table"
            columns={mediumColumns}
            dataSource={mediumDataSource}
            pagination={false}
            rowClassName={(record) =>
              record.key === "total_medium" ? "total-row" : ""
            }
            bordered
          />
        </div>
      </div>
    </div>
  );
};
export default ConsolidatedSummary;
