// import React from 'react';
// import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

// const data = [
//   { name: 'Conventional', value: 0 }, // 0% for Conventional
//   { name: 'Digital', value: 100 }, // 100% for Digital
// ];

// const COLORS = ['#FFA500', '#8CCB7E']; // Colors for the pie slices (Orange for 0%, Green for 100%)

// const CustomPieChart = () => {
//   // Custom label function to place labels inside the pie chart
//   const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
//     const RADIAN = Math.PI / 180;
//     const x = cx + (outerRadius / 2) * Math.cos(-midAngle * RADIAN); // Positioning inside the slice
//     const y = cy + (outerRadius / 2) * Math.sin(-midAngle * RADIAN); // Positioning inside the slice

//     return (
//       <text
//         x={x}
//         y={y}
//         fill="white" // Color of the text
//         textAnchor="middle"
//         dominantBaseline="middle"
//         fontSize={14} // Font size of the text
//       >
//         {value}%
//       </text>
//     );
//   };

//   return (
//     <div style={{ textAlign: 'center', margin: '20px' }}>
//       <h2>FOC - Conventional vs Digital</h2>
//       <PieChart width={400} height={400}>
//         <Pie
//           data={data}
//           cx={200}
//           cy={200}
//           outerRadius={80} // Keep the outer radius
//           dataKey="value"
//           label={renderLabel} // Use the custom label function
//           labelLine={false} // Hide the label lines
//           isAnimationActive={false} // Disable animation for static chart
//           stroke="none" // Remove stroke/border from pie slices
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index]} />
//           ))}
//         </Pie>
//         <Tooltip content={null} /> {/* Disable tooltip on hover */}
//         <Legend verticalAlign="bottom" />
//       </PieChart>
//     </div>
//   );
// };

// export default CustomPieChart;

// import React from "react";
// import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

// // Data representing the chart
// const data = [
//   { name: "Conventional", value: 0 }, // 0% for Conventional
//   { name: "Digital", value: 100 }, // 100% for Digital
// ];

// // Colors for the pie slices
// const COLORS = ["#FFA500", "#8CCB7E"]; // Orange for 0%, Green for 100%

// // Custom legend to display round markers
// const renderLegend = (props) => {
//   const { payload } = props;
//   return (
//     <>
//       console.log( "payload : " + JSON.stringify(payload) );
//       <ul style={{ listStyleType: "none", padding: 0, textAlign: "left" }}>
//         {payload.map((entry, index) => (
//           <li
//             key={`item-${index}`}
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <div
//               style={{
//                 width: "12px", // Size of the round marker
//                 height: "12px",
//                 borderRadius: "50%", // Make it round
//                 backgroundColor: entry.color, // Use the corresponding color
//                 marginRight: "8px",
//               }}
//             />
//             <span>{entry.name}</span>
//           </li>
//         ))}
//       </ul>
//     </>
//   );
// };

// const CustomPieChart = () => {
//   // Custom label function to place labels inside the pie chart
//   const renderLabel = ({
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     value,
//   }) => {
//     const RADIAN = Math.PI / 180;
//     const x = cx + (outerRadius / 2) * Math.cos(-midAngle * RADIAN); // Positioning inside the slice
//     const y = cy + (outerRadius / 2) * Math.sin(-midAngle * RADIAN); // Positioning inside the slice

//     return (
//       <text
//         x={x}
//         y={y}
//         fill="white" // Color of the text
//         textAnchor="middle"
//         dominantBaseline="middle"
//         fontSize={14} // Font size of the text
//       >
//         {value}%
//       </text>
//     );
//   };

//   return (
//     <div style={{ textAlign: "center", margin: "20px" }}>
//       <h2>FOC - Conventional vs Digital</h2>
//       <PieChart width={400} height={400}>
//         <Pie
//           data={data}
//           cx={200}
//           cy={200}
//           outerRadius={80} // Keep the outer radius
//           dataKey="value"
//           label={renderLabel} // Use the custom label function
//           labelLine={false} // Hide the label lines
//           isAnimationActive={false} // Disable animation for static chart
//           stroke="none" // Remove stroke/border from pie slices
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index]} />
//           ))}
//         </Pie>
//         <Tooltip content={null} /> {/* Disable tooltip on hover */}
//         <Legend content={renderLegend} verticalAlign="bottom" />{" "}
//         {/* Use custom legend */}
//       </PieChart>
//     </div>
//   );
// };

// export default CustomPieChart;

import React from "react";
import { Typography } from "antd";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const { Title } = Typography;

// Colors for the pie slices

// Custom legend to display round markers
const renderLegend = (props, data) => {
  const { payload } = props;
  return (
    <ul style={{ listStyleType: "none", padding: 0, textAlign: "left" }}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              width: "12px", // Size of the round marker
              height: "12px",
              borderRadius: "50%", // Make it round
              backgroundColor: entry.color, // Use the corresponding color
              marginRight: "8px",
            }}
          />
          <span style={{ fontSize: "14px", color: "#333", fontWeight: "500" }}>
            {entry.name || data[index].name}{" "}
            {/* Fallback to data array if name is empty */}
          </span>
        </li>
      ))}
    </ul>
  );
};

const CustomPieChart = ({ percentages, colors, labels ,title="FOC - Conventional vs Digital" }) => {
  const COLORS = colors; // Orange for Conventional, Green for Digital

//   const data = Object.keys(percentages).map((key, index) => ({
//     name: labels[index], // Use dynamic labels
//     value: parseFloat(percentages[key].replace("%", "")), // Convert percentage string to number
//   }));
  let data = [];
  if (percentages) {
    data = Object.keys(percentages).map((key, index) => {
      const percentageValue = percentages[key];
      const value = typeof percentageValue === 'string' 
        ? parseFloat(percentageValue.replace("%", "").trim()) 
        : 0;

      return {
        name: labels[index], // Use dynamic labels
        value: value, // Use parsed value
      };
    });
  }
  
  // Custom label function to place labels inside the pie chart
  const renderLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
    const RADIAN = Math.PI / 180;
    const x = cx + (outerRadius / 2) * Math.cos(-midAngle * RADIAN); // Positioning inside the slice
    const y = cy + (outerRadius / 2) * Math.sin(-midAngle * RADIAN); // Positioning inside the slice

    return (
      <text
        x={x}
        y={y}
        fill="white" // Color of the text
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14} // Font size of the text
      >
        {value}% {/* Display the percentage value */}
      </text>
    );
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Title level={4}>{title}</Title>

      <PieChart className="piechart-container" width={400} height={200}>
        <Pie
          data={data}
          cx={100}
          cy={100}
          outerRadius={80} // Keep the outer radius
          dataKey="value"
          label={renderLabel} // Use the custom label function
          labelLine={false} // Hide the label lines
          isAnimationActive={false} // Disable animation for static chart
          stroke="none" // Remove stroke/border from pie slices
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>
        <Tooltip content={null} /> {/* Disable tooltip on hover */}
        <Legend
          content={(props) => renderLegend(props, data)}
          layout="vertical" // Set layout to vertical for the legend
          align="right" // Align the legend to the right
          verticalAlign="middle" // Adjust vertical alignment if needed
        />
      </PieChart>
    </div>
  );
};

export default CustomPieChart;
