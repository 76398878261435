import React, { useEffect } from "react";
import "../ConsolidatedTab/consolidatedStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchBreakUpSummary } from "../../../../../features/PlanSummary/fetchBreakUpSummarySlice";
import { useParams } from "react-router-dom";

import BreakUpTable from "./BreakUpTable";
import CustomPieChart from "../Charts/FOC_Graph";

export default function BreakUpTabContent() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.fetchBreakUpSummary);
  const { plan_id } = useParams();

  useEffect(() => {
    dispatch(fetchBreakUpSummary(plan_id));
  }, [dispatch, plan_id]);

  // Check if data and plan are valid objects
  const hasValidPlans =
    data &&
    data.plan &&
    Array.isArray(data.plan.sites) &&
    data.plan.sites.length > 0;

  const percentages = {
    foc_percentage: data?.plan?.foc_percentage,
    paid_percentage: data?.plan?.paid_percentage,
  };
  console.log("percentages : " + JSON.stringify(percentages));
  const colors = ["#FFA500", "#8CCB7E"]; // Define any colors you need
  const labels = ["Conventional", "Digital"]; // Define your labels

  return (
    <div className="consolidated-tab-container">
      {hasValidPlans && percentages ? (
        <div style={{ display: "flex", gap: "5%" }}>
          <BreakUpTable apiResponse={data} title="Break up Paid vs FOC" />
          <CustomPieChart percentages={percentages} colors={colors} labels={labels} title="Paid vs FOC" />
        </div>
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}
