import React from "react";
import { Collapse } from "antd";
import "./summary.css";
import ConsolidatedTabContent from "./ConsolidatedTab/ConsolidatedTabContent";
import DigitalTabContent from "./Digital/DigitalTabContent";
import ConventionalTabContent from "./ConventionalTab/ConventionalTabContent";
import BreakUpTabContent from "./BreakUp";
import BreakUpTable from "./BreakUp/BreakUpTable";
import FinancialsTabContent from "./Financials";

const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras lobortis elementum quam, vitae mollis orci lobortis volutpat. Vivamus eros diam, molestie ac nulla ac, molestie tincidunt odio. Aliquam efficitur quis tortor sit amet pellentesque. Sed congue nulla eget nulla rutrum ultrices. Pellentesque iaculis dapibus est at ultricies. Aliquam erat volutpat. Mauris congue libero ac ante blandit, eget maximus mauris consequat. Suspendisse viverra nibh at risus vulputate, ut dignissim erat condimentum. Ut semper laoreet eros, at tincidunt ante tristique id. Praesent quis feugiat arcu. Suspendisse potenti. Nullam blandit mi ac sollicitudin feugiat.
`;
const items = [
  {
    key: "1",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "18%" }}> Plan Financials</span>
        <hr
          style={{
            flexGrow: 1,
            height: "1px",
            backgroundColor: "#AAAAAA",
            border: "none",
            marginLeft: "10px",
          }}
        />
      </div>
    ),
    children: <FinancialsTabContent/>,
  },

  {
    key: "2",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "15%" }}> Consolidated</span>
        <hr
          style={{
            flexGrow: 1,
            height: "1px",
            backgroundColor: "#AAAAAA",
            border: "none",
            marginLeft: "10px",
          }}
        />
      </div>
    ),
    children: <ConsolidatedTabContent />,
  },
  {
    key: "3",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "7%" }}> Digital</span>
        <hr
          style={{
            flexGrow: 1,
            height: "1px",
            backgroundColor: "#AAAAAA",
            border: "none",
            marginLeft: "10px",
          }}
        />
      </div>
    ),
    children: <DigitalTabContent />,
  },
  {
    key: "4",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "15%" }}>Conventional</span>
        <hr
          style={{
            flexGrow: 1,
            height: "1px",
            backgroundColor: "#AAAAAA",
            border: "none",
            marginLeft: "10px",
          }}
        />
      </div>
    ),
    children: <ConventionalTabContent />,
  },
  {
    key: "5",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ width: "32%" }}> Break Up (Paid Vs FOC)</span>
        <hr
          style={{
            flexGrow: 1,
            height: "1px",
            backgroundColor: "#AAAAAA",
            border: "none",
            //   marginLeft: "10px",
          }}
        />
      </div>
    ),
    // children: <BreakUpTabContent/>,
    children: <BreakUpTabContent />,

  },
];
export default function PlanSummaryContainer() {
  return (
    <div>
      <Collapse
        defaultActiveKey={["1"]}
        ghost
        items={items}
        className="custom-collapse"
      />
    </div>
  );
}
