import React from "react";
import { Table } from "antd";
import "../ConsolidatedTab/ConsolidatedSummary.css";

const formatNumber = (num) => {
  if (num === undefined || num === null) {
    return "-"; // Return a placeholder for undefined or null values
  }

  // Convert to number in case it's passed as a string
  num = Number(num);

  // Round the number to avoid floating-point precision issues
  const roundedNum = Math.round(num * 100) / 100; // Round to 2 decimal places

  if (roundedNum >= 1e12) {
    return (roundedNum / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
  } else if (roundedNum >= 1e9) {
    return (roundedNum / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
  } else if (roundedNum >= 1e6) {
    return (roundedNum / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
  } else if (roundedNum >= 1e3) {
    return (roundedNum / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
  } else {
    return roundedNum.toString(); // Less than 1000
  }
};
const Tables = ({ data }) => {
  const { result, finacials } = data;

  // Prepare data for the first table (sites)
  const sitesData = result?.sites?.map((site) => ({
    key: site.region_name, // or another unique identifier
    description: site.region_name,
    rentalCost: site.rental_cost,
    agencyCommission: site.agency_commission,
    grandTotal: site.grand_total,
  }));

  // Prepare data for the second table (financials)
  const financialsData = [
    {
      key: "sitesRental",
      description: "Sites Rental",
      rentalCost: finacials?.sites_rental,
      grandTotal: finacials?.total,
    },
    {
      key: "printing",
      description: "Printing",
      rentalCost: finacials?.printing_cost,
      grandTotal: finacials?.total,
    },
    {
      key: "fixedAssetFabrication",
      description: "Fixed Asset Fabrication",
      rentalCost: finacials?.fabrication_cost || 0, // Handle null
      grandTotal: finacials?.total,
    },
  ];

  const sitesColumns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Rental Cost",
      dataIndex: "rentalCost",
      key: "rentalCost",
      render: (text, record) => {
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "Agency Commission",
      dataIndex: "agencyCommission",
      key: "agencyCommission",
      render: (text, record) => {
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
      key: "grandTotal",
      render: (text, record) => {
        return <div>{formatNumber(text)}</div>;
      },
    },
  ];

  const financialsColumns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Rental Cost",
      dataIndex: "rentalCost",
      key: "rentalCost",
      render: (text, record) => {
        return <div>{formatNumber(text)}</div>;
      },
    },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
      key: "grandTotal",
      render: (text, record) => {
        return <div>{formatNumber(text)}</div>;
      },
    },
  ];

  return (
    <div className="consolidated-summary-2">
      <div className="summary-container">
        <div className="table-wrapper-2">
          <h3>Sites Summary</h3>
          <Table
            className="summary-table"
            dataSource={sitesData}
            bordered
            columns={sitesColumns}
            pagination={false}
          />
        </div>
        <div className="table-wrapper">
          <h3>Financial Summary</h3>
          <Table
            className="summary-table"
            bordered
            dataSource={financialsData}
            columns={financialsColumns}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Tables;
