import React, { useEffect } from "react";
import "./consolidatedStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsolidatedSummary } from "../../../../../features/PlanSummary/fetchconsolidatedSummarySlice";

import ConsolidatedTable from "./Table";
import { useParams } from "react-router-dom";
import ConsolidatedSummary from "./ConsolidatedSummary";
// export default function ConsolidatedTabContent() {
//   const dispatch = useDispatch();
//   const res = useSelector((state) => state);

//   console.log("response :  " + JSON.stringify(res?.fetchConsolidateSummary));
//   const { plan1, plan2, loading } = useSelector(
//     (state) => state.fetchConsolidateSummary
//   );
//   console.log("plan1 is : " + JSON.stringify(plan1));
//   const { plan_id } = useParams();
//   useEffect(() => {
//     dispatch(fetchConsolidatedSummary(plan_id));
//   }, []);
//   useEffect(() => {
//     dispatch(fetchConsolidatedSummary(plan_id));
//   }, [dispatch]);
//   return (
//     <div className="consolidated-tab-container">
//       {plan1 && <ConsolidatedSummary summaryData={plan1} mediumData={plan2} />}
//       {/* {plan1 && <ConsolidatedSummary summaryData={plan1} mediumData={plan2} />} */}
//       {/* <ConsolidatedTable tableData ={plan1}/> */}
//       {/* <ConsolidatedSummary summaryData={plan1} mediumData={plan2} /> */}
//     </div>
//   );
// }

export default function ConsolidatedTabContent() {
  const dispatch = useDispatch();
  //
  const { plan_id } = useParams();
  // const plan1 = {
  //   sites: [
  //     {
  //       city_name: "Karachi",
  //       quantity: 2,
  //       sov: "2128",
  //       sos: "1157000",
  //       sov_percentage: "100.0%",
  //       sos_percentage: "100.0%",
  //     },
  //     {
  //       city_name: "Lahore",
  //       quantity: 3,
  //       sov: "3150",
  //       sos: "1200000",
  //       sov_percentage: "95.0%",
  //       sos_percentage: "98.0%",
  //     },
  //     {
  //       city_name: "Islamabad",
  //       quantity: 1,
  //       sov: "1050",
  //       sos: "800000",
  //       sov_percentage: "87.5%",
  //       sos_percentage: "92.0%",
  //     },
  //   ],
  //   total_quantity: 6,
  //   total_sov: 6328,
  //   total_sos: 315700000,
  //   oma_percentage: 0.025,
  //   total_sov_percentage: "95.3%",
  //   total_sos_percentage: "98.5%",
  //   oma: 28925,
  //   total_plan: 3185925,
  // };
  // const plan2 = {
  //   sites: [
  //     {
  //       city_name: "Karachi",
  //       wall_pasting: "1",
  //       hoarding: "0",
  //       wall_panel: "0",
  //       digital_pole_signs: "0",
  //       digital_smd: "0",
  //       digital_screens: "0",
  //       digital_pylons: "0",
  //       mall_branding: "0",
  //       total_medium_count: "1",
  //     },
  //   ],
  //   total_hoarding: 0,
  //   total_wall_pasting: 1,
  //   total_wall_panel: 0,
  //   total_digital_pole_signs: 0,
  //   total_digital_smd: 0,
  //   total_digital_screens: 0,
  //   total_digital_pylons: 0,
  //   total_mallBranding: 0,
  //   total_mediums: 1,
  // };

  const { plan1, plan2, loading } = useSelector(
    (state) => state.fetchConsolidateSummary
  );

  useEffect(() => {
    dispatch(fetchConsolidatedSummary(plan_id));
  }, [dispatch, plan_id]);

  // Check if plan1 and plan2 are valid objects (not undefined or empty)
  const hasValidPlans =
    plan1 &&
    plan2 &&
    Object.keys(plan1).length > 0 &&
    Object.keys(plan2).length > 0;

  return (
    <div className="consolidated-tab-container">
      {hasValidPlans ? (
        <ConsolidatedSummary
          title1="Consolidated Summary (Conventional + Digital)"
          title2="Consolidated Summary (Conventional + Digital) Medium Wise"
          summaryData={plan1}
          mediumData={plan2}
        />
      ) : (
        <div>No data available.</div>
      )}
    </div>
  );
}
