// import React, { useState, useEffect } from "react";
// import { Table, Typography } from "antd";
// // import './FinancialsTable.css'; // Optional if you want to style externally
// import "./consolidatedStyles.css";
// import { WidthFull } from "@mui/icons-material";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchConsolidatedSummary } from "../../../../../features/PlanSummary/fetchconsolidatedSummarySlice";
// const { Title } = Typography;

// // const ConsolidatedTable = ({ tableData, plan_id }) => {
// //   console.log("data : " + JSON.stringify(tableData));
// //   const dispatch = useDispatch();
// //   const res = useSelector((state) => state);

// //   //   console.log("response :  " + JSON.stringify(res?.fetchConsolidateSummary));
// //   const { plan1 } = useSelector((state) => state.fetchConsolidateSummary);
// //     console.log("plan1 is : " + JSON.stringify(plan1));

// //   useEffect(() => {
// //     if (!tableData && plan1) dispatch(fetchConsolidatedSummary(plan_id));
// //   }, [dispatch]);
// //   // Table columns definition

// //   const { sites, total_quantity, total_sov, total_sos , total_sov_percentage ,total_sos_percentage} = tableData || plan1;

// //   // Creating the dataSource for the table
// //   const dataSource = [
// //     ...sites.map((item, index) => ({
// //       key: index,
// //       city_name: item.city_name,
// //       quantity: item.quantity,
// //       sov: item.sov,
// //       sov_percentage: item.sov_percentage,
// //       sos: item.sos,
// //       sos_percentage: item.sos_percentage,
// //     })),
// //     {
// //       key: 'total', // Total row
// //       city_name: 'Total',
// //       quantity: total_quantity,
// //       sov: total_sov,
// //       sov_percentage: total_sov_percentage, // Totals usually don't have percentages, leave empty
// //       sos: total_sos,
// //       sos_percentage: total_sos_percentage,
// //     },
// //   ];

// //   const columns = [
// //     {
// //       title: "City",
// //       dataIndex: "city_name",
// //       key: "city_name",
// //       className: "table-header",
// //       width: "20%",
// //     },
// //     {
// //       title: "Qty",
// //       dataIndex: "quantity",
// //       key: "quantity",
// //       align: "center",
// //       width: "20%",
// //       render: (text) => <span>{new Intl.NumberFormat().format(text)}</span>, // Formats number
// //     },
// //     {
// //       title: "SOV",
// //       dataIndex: "sov",
// //       key: "sov",
// //       align: "center",
// //       width: "20%",
// //     },
// //     {
// //       title: "SOV%",
// //       dataIndex: "sov_percentage",
// //       key: "sov_percentage",
// //       align: "center",
// //       width: "20%",
// //     },
// //     {
// //       title: "SOS",
// //       dataIndex: "sos",
// //       key: "sos",
// //       align: "center",
// //       width: "20%",
// //     },
// //     {
// //       title: "SOS%",
// //       dataIndex: "sos_percentage",
// //       key: "sos_percentage",
// //       align: "center",
// //       width: "20%",
// //     },

// //   ];

// // //   const dataSource = tableData || plan1;

// //   // Table data
// //   //   const dataSource = [
// //   //     {
// //   //       key: "1",
// //   //       description: "Sites Rental",
// //   //       rentalCost: 20699192,
// //   //       grandTotal: "OMM",
// //   //     },
// //   //     {
// //   //       key: "2",
// //   //       description: "Printing (Including F.A)",
// //   //       rentalCost: 6705955,
// //   //       grandTotal: "Multiple Printers",
// //   //     },
// //   //     {
// //   //       key: "3",
// //   //       description: "Fixed Asset Fabrication",
// //   //       rentalCost: 0, // Not provided
// //   //       grandTotal: "OMM",
// //   //     },
// //   //     {
// //   //       key: "4",
// //   //       description: <strong>Total</strong>,
// //   //       rentalCost: 27405147,
// //   //       grandTotal: null, // No Grand Total for the total row
// //   //     },
// //   //   ];

// //   return (
// //     <div className="financials-table-container">
// //       <Title level={3} className="financials-title">
// //         Financials
// //       </Title>
// //       <Table
// //         dataSource={dataSource.sites}
// //         columns={columns}
// //         pagination={false}
// //         bordered
// //         className="financials-table"
// //         summary={() => (
// //           <Table.Summary.Row>
// //             <Table.Summary.Cell colSpan={1}>
// //               <strong>Total</strong>
// //             </Table.Summary.Cell>
// //             <Table.Summary.Cell>
// //               <strong>27,405,147</strong>
// //             </Table.Summary.Cell>
// //             <Table.Summary.Cell />
// //           </Table.Summary.Row>
// //         )}
// //       />
// //     </div>
// //   );
// // };

// export default ConsolidatedTable;

import React, { useEffect } from "react";
import { Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsolidatedSummary } from "../../../../../features/PlanSummary/fetchconsolidatedSummarySlice";
import "./consolidatedStyles.css";
import "./ConsolidatedSummary.css" ;
const { Title } = Typography;

const ConsolidatedTable = ({ tableData, plan_id }) => {
  const dispatch = useDispatch();

  // Getting the response from the Redux store
  const { plan1 } = useSelector((state) => state.fetchConsolidateSummary);

  useEffect(() => {
    if (!tableData && plan1) {
      dispatch(fetchConsolidatedSummary(plan_id)); // Fetch the data if not available
    }
  }, [dispatch, tableData, plan1, plan_id]);

  // Safely extract data with default values to avoid undefined errors
  const {
    sites = [], // Default to an empty array to avoid mapping errors
    total_quantity = 0,
    total_sov = 0,
    total_sos = 0,
    total_sov_percentage = "",
    total_sos_percentage = "",
    oma_percentage = "",
    oma = 0,
    total_plan = 0,
  } = tableData || plan1 || {};

  // Creating the dataSource for the table
  const dataSource = [
    ...sites.map((item, index) => ({
      key: index,
      city_name: item.city_name,
      quantity: item.quantity,
      sov: item.sov,
      sov_percentage: item.sov_percentage,
      sos: item.sos,
      sos_percentage: item.sos_percentage,
    })),
  ];

  const columns = [
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
      className: "table-header",
      width: "20%",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "20%",
      render: (text) => <span>{new Intl.NumberFormat().format(text)}</span>, // Formats number
    },
    {
      title: "SOV",
      dataIndex: "sov",
      key: "sov",
      align: "center",
      width: "20%",
    },
    {
      title: "SOV%",
      dataIndex: "sov_percentage",
      key: "sov_percentage",
      align: "center",
      width: "20%",
    },
    {
      title: "SOS",
      dataIndex: "sos",
      key: "sos",
      align: "center",
      width: "20%",
    },
    {
      title: "SOS%",
      dataIndex: "sos_percentage",
      key: "sos_percentage",
      align: "center",
      width: "20%",
    },
  ];

  // Only render the table when sites data is available
  return (
    <div className="financials-table-container">
      <Title level={3} className="financials-title">
        Financials
      </Title>
      {/* <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        className="financials-table"
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={1}>
              <strong>Total</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              <strong>{new Intl.NumberFormat().format(total_quantity)}</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              <strong>{new Intl.NumberFormat().format(total_sov)}</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell>{total_sov_percentage}</Table.Summary.Cell>
            <Table.Summary.Cell>
              <strong>{new Intl.NumberFormat().format(total_sos)}</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell>{total_sos_percentage}</Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      /> */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        className="financials-table"
        summary={() => (
          <>
            {/* First Summary Row */}
            <Table.Summary.Row className="total-row">
              <Table.Summary.Cell colSpan={1}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>
                  {new Intl.NumberFormat().format(total_quantity)}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{new Intl.NumberFormat().format(total_sov)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{total_sov_percentage}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{new Intl.NumberFormat().format(total_sos)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{total_sos_percentage}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>

            {/* Second Summary Row */}
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={4}>
                <strong>OMA</strong> <strong>{oma_percentage}</strong>
              </Table.Summary.Cell>

              <Table.Summary.Cell colSpan={1}>
                <strong>{new Intl.NumberFormat().format(oma)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>

            {/* Add more summary rows as needed */}
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={4}>
                <strong>Total Plan</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}>
                <strong>{new Intl.NumberFormat().format(total_plan)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={1}>
                <strong>{}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
    </div>
  );
};

export default ConsolidatedTable;
